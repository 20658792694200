@import '../../static/styles/variables';

.stepper-list {
  color: $grey-4;
  padding-left: ($h * 7);

  @media screen and (min-width: $sm) {
    padding-left: 0;
  }

  &__item {
    border-bottom: solid 1px $grey-3;
    margin-right: -($h * 2);
    padding: ($v * 3) 0;
    position: relative;

    @media screen and (min-width: $sm) {
      margin-right: 0;
      padding-left: ($h * 9);
    }

    &:last-child {
      border-bottom: 0;
    }

    &:before {
      border-radius: 50%;
      border: solid 3px $grey-3;
      color: $grey-4;
      content: attr(data-id);
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      height: 36px;
      left: -($h * 7);
      line-height: 32px;
      position: absolute;
      text-align: center;
      top: 10px;
      width: 36px;

      @media screen and (min-width: $sm) {
        left: ($h * 2);
      }
    }

    &--completed {
      color: $ac-black;

      &:before {
        background-color: $ac-green;
        background-image: url('../../static/images/icons/tick-white.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-color: $ac-green;
        color: $grey-4;
        content: '';
      }
    }

    &--active {
      &:before {
        border-color: $ac-black;
        color: $ac-black;
      }
    }
  }

  @media screen and (min-width: $sm) {
    background-color: $white;
    border-radius: $border-radius-base;
    border: solid 1px $grey-3;
  }
}
