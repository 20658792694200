.sortableColumn {
  text-decoration: underline;
  user-select: none;
}

.locked-account-icon {
  max-height: 10px;
  margin-right: 10px;
}

.terms-defs {
  display: flex;
  margin-bottom: 10px;

  .terms-def {
    margin-right: 20px;
  }
}

.terms-accepted {
  color: #12b447;
}
.terms-not-accepted {
  color: #c23131;
}