@import '../../static//styles/variables';
$send-document-modal-bp: $sm;

#send-document-modal > div {
  background-color: transparentize($color: #000, $amount: .5);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}

.send-document-modal-container {
  align-items: stretch;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  text-align: center;

  @media screen and (min-width: $send-document-modal-bp) {
    align-items: center;
  }

  &__header {
    text-align: center;
  }

  &__inner {
    background-color: $white;
    max-width: $send-document-modal-bp;
    padding: ($v * 3) ($h * 3);
    width: 100%;
    max-width: 320px;
  }

  &__close {
    background-color: $grey-3;
    border-radius: 100%;
    border: 0;
    color: $grey-6;
    cursor: pointer;
    height: 30px;
    position: absolute;
    right: $h;
    text-align: center;
    top: $v;
    width: 30px;

    @media screen and (min-width: $send-document-modal-bp) {
      height: 50px;
      width: 50px;
    }

    &:after {
      bottom: 0;
      content: '\00d7';
      font-size: 30px;
      left: 0;
      line-height: 1;
      position: absolute;
      right: 0;
      top: 0;

      @media screen and (min-width: $send-document-modal-bp) {
        font-size: 50px;
      }
    }
  }

  img {
    width: 100%;
  }

  iframe {
    border: none;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  object {
    min-height: 400px;
  }
}
