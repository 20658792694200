@import '../../static/styles/variables';

.tablist {
  &__nav {
    border-bottom: solid 3px $grey-2;
    margin-bottom: $v * 5;
  }

  &__nav-item {
    &:link,
    &:visited {
      display: inline-block;
      cursor: pointer;
      padding: ($v * 2) ($h * 2);
      user-select: none;
      text-decoration: none;
      color: $ac-black;
      font-size: 16px;
    }

    &--active:link,
    &--active:visited {
      box-shadow: 0 3px 0 0 $grey-6;
    }
  }

  &__content-radio {
    display: none;
  }

  &__content-item {
    display: none;
  }

  .tablist__content-item-selected {
    display: block;
  }
}
