// Arnold Clark Black
$ac-black: #2d3737;
$ac-black-1: #ebebeb;
$ac-black-2: #d5d7d6;
$ac-black-3: #abafb0;
$ac-black-4: #818787;
$ac-black-5: #57605f;
$ac-black-6: #242d2c;
$ac-black-7: #1b2121;
// Arnold Clark Yellow
$ac-yellow: #ffde00;
$ac-yellow-1: #fefce5;
$ac-yellow-2: #fff9cb;
$ac-yellow-3: #fff299;
$ac-yellow-4: #feec66;
$ac-yellow-5: #fee533;
$ac-yellow-6: #ccb100;
$ac-yellow-7: #998500;
// Arnold Clark Blue
$ac-blue: #00a1e0;
$ac-blue-1: #e6f5fc;
$ac-blue-2: #cdedfa;
$ac-blue-3: #98daf2;
$ac-blue-4: #66c8ed;
$ac-blue-5: #34b5e6;
$ac-blue-6: #0182b3;
$ac-blue-7: #006186;
// Arnold Clark Teal
$ac-teal: #00b4bb;
$ac-teal-1: #e4f8f9;
$ac-teal-2: #cceff1;
$ac-teal-3: #9ae1e5;
$ac-teal-4: #66d1d7;
$ac-teal-5: #34c3c9;
$ac-teal-6: #019096;
$ac-teal-7: #006c6f;
// Arnold Clark Violet
$ac-violet: #87189d;
$ac-violet-1: #f3e8f6;
$ac-violet-2: #e8d1ed;
$ac-violet-3: #cfa3d8;
$ac-violet-4: #b875c5;
$ac-violet-5: #9e47b0;
$ac-violet-6: #6c137d;
$ac-violet-7: #510e5e;
// Arnold Clark Magenta
$ac-magenta: #d41568;
$ac-magenta-1: #fce8f1;
$ac-magenta-2: #f7d1e2;
$ac-magenta-3: #eda2c3;
$ac-magenta-4: #e573a6;
$ac-magenta-5: #dc4487;
$ac-magenta-6: #a81053;
$ac-magenta-7: #7e0c3f;
// Arnold Clark Bluegrey
$ac-bluegrey: #607882;
$ac-bluegrey-1: #f0f1f3;
$ac-bluegrey-2: #dfe4e7;
$ac-bluegrey-3: #bfc8cd;
$ac-bluegrey-4: #a1aeb4;
$ac-bluegrey-5: #80939a;
$ac-bluegrey-6: #4c6067;
$ac-bluegrey-7: #3b4c53;
// Arnold Clark Green
$ac-green: #12b447;
$ac-green-1: #e7f7ec;
$ac-green-2: #d0f0d9;
$ac-green-3: #a0e1b5;
$ac-green-4: #72d292;
$ac-green-5: #41c36b;
$ac-green-6: #0e9038;
$ac-green-7: #096c2b;
// Arnold Clark Red
$ac-red: #c23131;
$ac-red-1: #f8eaea;
$ac-red-2: #f4d6d6;
$ac-red-3: #e7adac;
$ac-red-4: #db8382;
$ac-red-5: #ce5b5a;
$ac-red-6: #9b2827;
$ac-red-7: #7d1f1f;
// Arnold Clark Orange
$ac-orange: #fb8c00;
$ac-orange-1: #fef3e5;
$ac-orange-2: #fee8cc;
$ac-orange-3: #fdd199;
$ac-orange-4: #fdba66;
$ac-orange-5: #fca333;
$ac-orange-6: #c97000;
$ac-orange-7: #975400;
// Solids
$white: #fff;
$black: #000;
// Greys
$grey-1: #fafafa;
$grey-2: #f2f2f2;
$grey-3: #e5e5e5;
$grey-4: #cccccc;
$grey-5: #999999;
$grey-6: #666666;
$grey-7: #333333;
// Pricing
$ac-pricing: #155991;
// Rhythm
$v: 6px;
$h: 7px;
// Breakpoints
$xs: 480px;
$sl: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
// Scaffolding
$link-color: #007fb0;
$link-color-hover: darken($link-color, 15%);
// Radius
$border-radius-base: 4px;
$border-radius-sm: 3px;
$border-radius-lg: 6px;
// Grid system
$grid-columns: 12;
// Containers
$container-sl: 540px;
$container-sm: 720px;
$container-md: 940px;
$container-lg: 1200px;
// Typography
$font-size-base: 16px;
$font-size-xs: ceil($font-size-base * 0.75); // 12px
$font-size-sm: ceil($font-size-base * 0.875); // 14px
$font-size-lg: ceil($font-size-base * 1.125); // 18px
$font-size-xl: ceil($font-size-base * 1.375); // 22px
$font-size-brand: ceil($font-size-base * 1.5); // 24px
$font-weight-book: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$measure: 548px;
// Forms & Buttons
$input-bg-focus: #fdfef3;
