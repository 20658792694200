@import '../../static/styles/variables';

.panel {
  &--bordered {
    border-bottom: solid 1px $grey-4;
    border-top: solid 1px $grey-4;
  }

  &--white {
    background-color: $white;
    border-color: $grey-3;
  }

  &--boxed {
    border: 0;
  }

  @media screen and (min-width: $sm) {
    &--bordered-sm {
      border: solid 1px $grey-4;
    }

    &--boxed {
      border: solid 1px $grey-4;
      border-radius: $border-radius-base;
      padding: ($v * 4) ($h * 3);
    }

    &--grey-sm {
      background-color: $grey-2;
      border-color: $grey-3;
    }

    &--white-sm {
      background-color: $white;
      border-color: $grey-3;
    }
  }
}
