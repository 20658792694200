@import '../../static/styles/variables';

.rolecard {
  height: 27px;
  margin-bottom: 19px;
  position: relative;

  @media screen and (min-width: $sm) {
    background-color: $white;
    border-radius: $border-radius-base;
    border: solid 1px $grey-3;
    height: 126px;
    padding: ($v * 3) ($h * 2);
  }

  &__title {
    display: none;

    @media screen and (min-width: $sm) {
      display: block;
    }
  }

  &__details {
    font-weight: 500;
    line-height: 27px;

    @media screen and (min-width: $sm) {
      font-size: 18px;
      max-width: 200px;
    }
  }

  &__link {
    @media screen and (min-width: $sm) {
      color: $ac-black;
      text-decoration: none;

      &:hover, &:focus {
        color: $ac-black;
      }

      &:after {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &--icon {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: ($h * 5);

    @media screen and (min-width: $sm) {
      background-position: right center;
      background-size: inherit;
      padding-left: inherit;
    }
  }

  &--icon-person {
    background-image: url('../../static/components/rolecard/icons/person.svg');

    @media screen and (min-width: $sm) {
      background-image: url('../../static/components/rolecard/icons/person-large.svg');
    }
  }

  &--icon-calendar {
    background-image: url('../../static/components/rolecard/icons/calendar.svg');

    @media screen and (min-width: $sm) {
      background-image: url('../../static/components/rolecard/icons/calendar-large.svg');
    }
  }

  &--icon-location {
    background-image: url('../../static/components/rolecard/icons/location.svg');

    @media screen and (min-width: $sm) {
      background-image: url('../../static/components/rolecard/icons/location-large.svg');
    }
  }
}
