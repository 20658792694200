@import '../../static/styles/variables';

.lds-ellipsis {
  display: inline-block;
  height: 64px;
  position: relative;
  width: 64px;

  div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: $grey-5;
    border-radius: 50%;
    height: 11px;
    position: absolute;
    top: 27px;
    width: 11px;

    &:nth-child(1) {
      animation: lds-ellipsis1 .6s infinite;
      left: 6px;
    }

    &:nth-child(2) {
      animation: lds-ellipsis2 .6s infinite;
      left: 6px;
    }

    &:nth-child(3) {
      animation: lds-ellipsis2 .6s infinite;
      left: 26px;
    }

    &:nth-child(4) {
      animation: lds-ellipsis3 .6s infinite;
      left: 45px;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}
