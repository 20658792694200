@import '../../static/styles/variables';
$break: 'screen and (min-width: 992px)';

.stepper {
  background-color: white;
  border-bottom: solid 1px $ac-green-5;
  box-sizing: border-box;
  display: block;
  font-weight: 500;
  padding: ($v * 2) ($h * 2);
  position: relative;

  &__link {
    color: inherit;
    user-select: none;
    text-decoration: none;
    pointer-events: none;
    &--current {
      user-select: none;
      text-decoration: none;
      pointer-events: none;
    }
    &--done {
      pointer-events: all;
      text-decoration: underline;
    }
  }

  @media #{$break} {
    background-color: transparent;
    border: 0;
    display: flex;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0;
  }

  &__item {
    display: none;
    position: relative;

    &--current {
      display: block;
    }

    @media #{$break} {
      display: block;
      flex: 1;
      text-align: center;

      &:not(:last-child):before {
        background-color: $ac-black-2;
        border: 0;
        content: '';
        display: block;
        height: 6px;
        left: 50%;
        position: absolute;
        top: 45%;
        width: 100%;
        z-index: -1;
      }

      &--done {
        &:not(:last-child):before {
          background-color: $ac-green;
        }
      }
    }
  }

  &__item-number {
    &:before {
      content: 'Step ';
    }

    &:after {
      content: ': ';
    }

    @media #{$break} {
      background-color: $white;
      border-radius: 100%;
      border: solid 4px $ac-black-2;
      color: $ac-black-2;
      display: inline-block;
      font-weight: 500;
      height: 35px;
      line-height: 27px;
      margin-bottom: 11px;
      margin-top: 30px;
      width: 35px;

      &--current {
        border-color: $ac-black;
        color: $ac-black;
      }

      &--done {
        background-color: $ac-green;
        background-image: url('../../static/images/icons/tick-white.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-color: $ac-green;
        color: $white;
        text-indent: -99999px;
      }

      &:before {
        content: '';
      }

      &:after {
        content: '';
      }
    }
  }

  &__item-title {
    @media #{$break} {
      display: block;

      &--current {
        font-weight: 500;
      }
    }
  }
}

.stepper-progress {
  border-top: solid 3px $ac-green;
  clear: both;
  display: block;
  height: 0;

  @media #{$break} {
    border: 0;
  }
}
