.file-upload {
  border: dashed 1px #979797;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  text-align: center;
  width: 100%;

  input[type='file'] {
    bottom: 0;
    position: absolute;
    top: -50px;
    width: 100%;

    &:focus, &:active {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #e5e5e5;
  }
}
