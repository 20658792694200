.details-list {
  &__dt {
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 15px;
    &:first-child {
      margin-top: 0;
    }
  }

  li, &__dd {
    font-weight: 400;
    list-style: none;
    padding: 0;
  }
}
