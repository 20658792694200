@import '../../static/styles/variables';

.info-box {
  &--question {
    background-image: url('../../static/components/info-box/chat.svg');
    background-position: right 14px top 12px;
    background-repeat: no-repeat;
    @media screen and (min-width: $md) {
      background-position: right 14px top 32px;
    }
  }
  &--mobile {
    background-image: url('../../static/components/info-box/mobile.svg');
    background-repeat: no-repeat;
    background-position: right 14px top 10px;

    @media screen and (min-width: $md) {
      background-position: right 14px top 38px;
      padding-bottom: 20px;
    }
  }

  @media screen and (min-width: $xs) {
    border-radius: $border-radius-base;
    margin-bottom: 10px;
  }
  @media screen and (min-width: $md) {
    min-height: 180px;
  }
  @media screen and (min-width: $lg) {
    min-height: 150px;
  }
}
