@import '../../static/styles/variables';

.hero {
  &__row {
    min-height: 80px;

    @media screen and (min-width: 768px) {
      min-height: 120px;
    }
  }

  &__pre-title {
    color: rgba(#3b4747, .6);
    font-weight: 500;
  }

  &__title {
    font-weight: 500;

    &--light {
      font-weight: 400;
    }
  }

  &__icon {
    background-position: right 14px center;
    background-repeat: no-repeat;
    background-size: contain;

    &--chat {
      background-image: url('../../static/components/hero/chat.svg');
    }

    &--doc {
      background-image: url('../../static/components/hero/doc-alt.svg');
    }

    &--person {
      background-image: url('../../static/components/hero/person.svg');
    }

    &--no-mobile {
      @media screen and (max-width: $sm) {
        background-image: none;
      }
    }
  }
}
